/**
 * This utility wraps a fetcher so requests are batched together and executed after a delay.
 * It does NOT handle deduplication by keys: Invoking the returned function with the same key
 * multiple times within the same execution interval will result in duplicate keys in the request
 * to the server which must each receive a corresponding returned value.
 * If the batch fetch fails only one returned promise rejects with the cause,
 * the remainder reject with an undefined cause.
 * This is to avoid a potential common case of devs not attaching rejection handlers to the promise
 * and triggering our {@link UnhandledPromiseRejectionHandler.ts} event handler many times.
 *
 * @param fetch function to map keys to values asynchronously, usually a Rest request to fetch values for entities by id
 *              It MUST return values in the same order as the keys
 * @param delay time in ms to wait between first invocation and batched fetch, default 0 ms
 * @return function function to fetch a single value which will be batched with other fetches
 * If the batched fetch fails, only one of the returned promises for the batch will receive the rejection reason
 */
export function batchFetcher<K, V>(
    fetch: (keys: K[]) => Promise<V[]>,
    delay = 0,
): (key: K) => Promise<V> {
    let nextBatch: {
        key: K;
        resolve: (value: V | PromiseLike<V>) => void;
        reject: (reason?: unknown) => void;
    }[] = [];
    let isActive = 0;

    return (key) =>
        new Promise<V>((resolve, reject) => {
            nextBatch.push({ key, resolve, reject });
            // Specify window purely to satisfy jest.
            // Something about our compilation config causes jest to use the Node typing of this function
            // which does not return a number. This causes the jest test to fail.
            isActive ||= window.setTimeout(handleBatch, delay);
        });

    async function handleBatch() {
        while (nextBatch.length) {
            const batch = nextBatch;
            nextBatch = [];
            try {
                const values = await fetch(batch.map((req) => req.key));
                if (values.length !== batch.length) {
                    throw Error(`Got ${values.length} items, expected ${batch.length}`);
                }
                batch.forEach((req, i) => req.resolve(values[i]));
            } catch (err) {
                // We only pass the rejection reason to the first promise of the batch.
                // This avoids nuisance of an error being handled N times, ex. N popups or bugsnag reports
                // If the caller of this method does not attach a catch block there will be a single unhandled rejection
                // See UnhandledPromiseRejectionHandler.ts, if event.reason === undefined nothing occurs.
                batch.forEach((req, index) => req.reject(index === 0 ? err : undefined));
            }
        }
        clearTimeout(isActive);
        isActive = 0;
    }
}

/**
 * This utility caches the results of successful fetches by a fetching function.
 * Failed fetches are not cached.
 * It is often useful to use this in conjunction with batchFetcher to cache the results of its batching.
 * ex. cachedFetcher(batchFetcher(fetchFn))
 * This is a rare case where the object type is appropriate as we use a WeakMap which needs object keys.
 *
 * @param fetch a function that returns a promise for a value
 * @param cache optional backing cache if it is desired to clear values
 */
export function cachedFetcher<K extends object, V>(
    fetch: (keys: K) => Promise<V>,
    cache = new WeakMap<K, Promise<V>>(),
): (key: K) => Promise<V> {
    // cache is a WeakMap so that we don't leak memory if the keys are no longer referenced.
    return (key) => {
        let promise = cache.get(key);
        if (!promise) {
            promise = fetch(key);
            cache.set(key, promise);
            // Evict on failed fetch.
            // This creates a new promise and doesn't alter the fulfillment/rejection value of the original promise.
            promise.catch(() => cache.delete(key));
        }
        return promise;
    };
}
